@font-face {
  font-family: "Product Sans";
  src: url("../public/font/ProductSans-Regular.ttf");
}

* {
  font-family: "Product Sans" !important;

  /* REMOVE SCROLL BAR */
  -ms-overflow-style: none;   /* Internet Explorer 10+ */
  scrollbar-width: none;      /* Firefox */
}

/* REMOVE SCROLL BAR */
*::-webkit-scrollbar {
  display: none;              /* Safari and Chrome */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
